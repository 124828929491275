import request from '@/plugins/axios';

// 修改个人信息
export function updatePersonInfo(obj) {
  return request({
    url: `/crete/sign/up`,
    method: 'put',
    data: obj
  })
}

// 验证密码
export function confirmPwd(obj) {
  return request({
    url: `/security/confirmPwd`,
    method: 'post',
    params: obj
  })
}

// 更改密码
export function updatePassword(obj) {
  return request({
    url: `/security/updatePassword`,
    method: 'post',
    params: obj
  })
}

// 查询用户及报名信息
export function getSignUpData(id) {
  return request({
    url: `/crete/sign/up/getSignUpData/${id}`,
    method: 'get',
  })

}
