<template>
  <div class="mobile-bottom-tab">
    <div v-for="(item, index) in navList" :key="index" class="tab-item" :class="item.className"
         @click="jumpPage(item.routerName)">
      <div :class="routerName == item.routerName? 'icon-active': 'icon'"></div>
      <div :class="{'active-label':routerName == item.routerName}">
        {{ item.label }}
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "FooterBar",
    props: {
      active: {
        type: Number,
        default: 1
      },
      authorityList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        navList: [
          {label: '竞赛', routerName: 'home', className: 'tab-event'},
          {label: '我的', routerName: 'mobile-personal-center', className: 'tab-personal'},
        ]
      };
    },
    computed: {
      routerName() {
        return this.$route.name
      }
    },
    methods: {
      jumpPage(name) {
        if (this.routerName == name) return;
        this.$router.push({name});
      }
    }
  };
</script>
<style lang="scss" scoped>
  .mobile-bottom-tab {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: #2888EC;
    padding: 5px 16%;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    z-index: 99;
    font-size: 14px;

    .tab-item {
      text-align: center;
      .icon {
        background: url("../../../../assets/img/home/mobile-event.png") no-repeat center;
        width: 40px;
        height: 40px;
        display: inline-block;
        background-size: 100%;
      }

      .icon-active {
        background: url("../../../../assets/img/home/mobile-event-active.png") no-repeat center;
        width: 40px;
        height: 40px;
        display: inline-block;
        color: #19ada9;
        background-size: 100%;
      }
      .active-label {
        color: #F5DD8A
      }

      div {
        &:last-child {
          margin-top: -5px;
        }
      }
    }
    /* 竞赛 */
    .tab-event {
      .icon {
        background: url("../../../../assets/img/home/mobile-event.png") no-repeat center;
        background-size: 100%;
      }
      .icon-active {
        background: url("../../../../assets/img/home/mobile-event-active.png") no-repeat center;
        background-size: 100%;
      }
    }
    /* 我的 */
    .tab-personal {
      .icon {
        background: url("../../../../assets/img/home/mobile-my.png") no-repeat center;
        background-size: 100%;
      }
      .icon-active {
        background: url("../../../../assets/img/home/mobile-my-active.png") no-repeat center;
        background-size: 100%;
      }
    }
  }
</style>