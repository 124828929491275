import request from '@/plugins/axios'

const basePath = '/tocuser'

export const EXPORT_URL = `${basePath}/export`

export function fetchList(query) {
  return request({
    url: `${basePath}/page`,
    method: 'get',
    params: query
  })
}

/**
 * 查询机构班级学生信息分页查询
 * @param {Object} query
 * @param {String} query.classId 班级ID
 */
export function fetchStudentList(query) {
  return request({
    url: `${basePath}/getStudentInfo/page`,
    method: 'get',
    params: query
  })
}

export function getObj(id) {
  return request({
    url: `${basePath}/${id}`,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: `${basePath}/${id}`,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: basePath,
    method: 'put',
    data: obj
  })
}

/**
 * 解绑实验空间用户
 */
export function unbindIlabx() {
  return request({
    url: `${basePath}/ilabx/unbind`,
    method: 'put'
  })
}

export function getTocUserInfo() {
  return request({
    url: `${basePath}/info`,
    method: 'get'
  })
}

export function updataObj(obj) {
  return request({
    url: `${basePath}/updateUserInfoById`,
    method: 'post',
    data: obj
  })
}
