<template>
  <div>
    <el-form ref="form" :model="model" :rules="rules" size="medium" v-loading="loading">
      <!-- 用户名 -->
      <el-form-item prop="mobile">
        <el-input v-model="model.mobile" placeholder="请输入手机号" prefix-icon="el-icon-phone" :disabled="sendMsgLoading"
                  clearable @keyup.native.enter="handleGetVerifyCode" />
      </el-form-item>

      <el-form-item class="msg-verify-code" prop="code">
        <el-input v-model="model.code" placeholder="请输入验证码" clearable @keyup.native.enter="handleRegister" />
        <el-button type="primary" :disabled="sendMsgLoading" :loading="sendMsgLoading && verifyCodeResendSecond < 0" plain
                   @click="handleGetVerifyCode">{{ sendMsgLoading ? verifyCodeResendSecond < 0 ? '发送中' :
          `${verifyCodeResendSecond}s后重试` : '获取验证码' }}</el-button>
      </el-form-item>

      <!-- 密码 -->
      <el-form-item prop="password">
        <el-input v-model="model.password" placeholder="请输入密码" prefix-icon="el-icon-lock" autocomplete="new-password"
                  clearable show-password @keyup.native.enter="handleRegister" />
      </el-form-item>

      <el-form-item prop="againPassword">
        <el-input v-model="model.againPassword" placeholder="请再次输入密码" prefix-icon="el-icon-lock"
                  autocomplete="new-password" clearable show-password @keyup.native.enter="handleRegister" />
      </el-form-item>

      <el-form-item>
        <div class="desc-box">
          <div>
            <el-checkbox v-model="checked" disabled></el-checkbox>
            <span class="read-over" @click="showSecret = true">已阅读隐私政策</span>
          </div>

          <span>已有账号，去<span class="desc-key" @click="handleLogin">登录</span></span>
        </div>
      </el-form-item>

      <!-- 注册按钮 -->
      <el-form-item>
        <el-button class="form-login" type="primary" :loading="loading" @click="handleRegister">注 册</el-button>
      </el-form-item>
    </el-form>
    <secret-description-dialog v-model="showSecret" :show-bottom="false"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { isMobile, isValidPassword, encryptPhoneNumber } from "@/utils/validate"
import { sendRegisterSms } from "@/api/admin/sms"
import { register } from "@/api/core/toc-user-manage"
import SecretDescriptionDialog from './secret-description-dialog';
export default {
  name: "registerForm",
  data() {
    const validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!isMobile(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
      } else if (!isValidPassword(value).valid) {
        callback(new Error(isValidPassword(value).message))
      } else {
        if (this.model.againPassword !== '') {
          this.$refs['form']?.validateField('againPassword')
        }
        callback()
      }
    }
    const validatePasswordAgain = (rule, value, callback) => {
      const { password } = this.model || {}
      if (!password) {
        callback()
      } else if (!value) {
        callback(new Error('请再次输入密码'))
      } else if (value !== password) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      showSecret:false,
      checked:true,
      loading: false,
      sendMsgLoading: false,
      verifyCodeWaitSeconds: 60,
      verifyCodeResendSecond: 0,
      verifyCodeTimer: null,
      model: {},
      rules: {
        mobile: [
          { required: true, validator: validateMobile }
        ],
        code: [
          { required: true, message: '请输入验证码' }
        ],
        password: [
          { required: true, validator: validatePassword }
        ],
        againPassword: [
          { required: true, validator: validatePasswordAgain }
        ]
      }
    }
  },
  computed: {
    ...mapState(['registerVerifyCodeTimeMap']),
  },
  components:{
    SecretDescriptionDialog
  },
  created() {
    this.model = {}
  },
  mounted() {
    this.$refs['form']?.clearValidate()
  },
  destroyed() {
    this.clearVerifyCodeTimer()
  },
  methods: {
    ...mapMutations(['REGISTER_VERIFY_CODE_SEND_TIME']),
    registerByMobile() {
      const { model = {} } = this
      let { mobile, code, password } = model || {}
      this.loading = true
      register({
        username: mobile,
        mobile,
        name: `手机用户${encryptPhoneNumber(mobile)}`,
        // sex: null,
        code,
        password
      }).then(() => {
        this.$message.success('注册成功！')
        this.handleLogin()
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 点击 注册 按钮
     */
    handleRegister() {
      this.loading = true
      this.$refs['form']?.validate((valid) => {
        if (valid) {
          this.registerByMobile()
        } else {
          this.loading = false
        }
      })
    },
    /**
     * 去登录
     */
    handleLogin() {
      this.$emit('change-type', 'login')
    },
    /**
     * 发送验证码倒计时
     */
    sendVerifyCodeTimer(seconds = this.verifyCodeWaitSeconds) {
      this.clearVerifyCodeTimer()
      this.verifyCodeResendSecond = seconds
      this.sendMsgLoading = true
      this.verifyCodeTimer = setInterval(() => {
        this.verifyCodeResendSecond--
        if (this.verifyCodeResendSecond === 0) {
          this.clearVerifyCodeTimer()
        }
      }, 1000)
    },
    /**
     * 清除发送验证码倒计时
     */
    clearVerifyCodeTimer() {
      if (this.verifyCodeTimer) {
        clearInterval(this.verifyCodeTimer)
      }
      this.sendMsgLoading = false
    },
    /**
     * 发送验证码
     */
    sendVerifyCode() {
      const { verifyCodeWaitSeconds, model, registerVerifyCodeTimeMap } = this
      let { mobile } = model || {}
      let { [mobile]: lastSendTime } = registerVerifyCodeTimeMap || {}
      let currentTime = new Date().getTime()
      let seconds = Math.ceil((currentTime - lastSendTime) / 1000)
      if (seconds < verifyCodeWaitSeconds) {
        this.$message.info('距离上次发送验证码间隔太短，请稍后再试！')
        this.sendVerifyCodeTimer(verifyCodeWaitSeconds - seconds)
        return
      }
      this.verifyCodeResendSecond = -1
      this.sendMsgLoading = true
      delete this.model.code
      sendRegisterSms(mobile).then(() => {
        this.$message.success("验证码发送成功，请查收！")
        this.REGISTER_VERIFY_CODE_SEND_TIME(mobile)
        this.sendVerifyCodeTimer(verifyCodeWaitSeconds)
      }).catch(() => {
        this.sendMsgLoading = false
      })
    },
    /**
     * 点击 获取验证码
     */
    handleGetVerifyCode() {
      this.loading = true
      this.$refs['form']?.validateField('mobile', (valid) => {
        if (!valid) {
          this.sendVerifyCode()
        }
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .read-over{
    cursor: pointer;
    margin-left:3px;
  }
</style>
