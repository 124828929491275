<!-- 不要格式化这个文件，切记切记！-->
<template>
  <el-dialog custom-class="secret-description-dialog"
             :visible.sync="visible"
             :append-to-body="true"
             :close-on-click-modal="false"
             @close="handleClose"
             @open="handleOpen"
             :width="isMobilePlatform?'90%':'1200px'">
    <h3>中兴捧月巧匠精英挑战赛隐私政策</h3>
    <div class="desc-content" :class="{'mobile-desc-content':isMobilePlatform}" ref="descContent">
      <p>发布生效日期：2024年06月06日</p>
      <p>本政策适用于中兴捧月巧匠精英挑战赛相关产品和服务。</p>
      <p>本产品与/或服务是由中兴通讯股份有限公司及武汉丰迈信息技术有限公司（中兴通讯股份 有限公司注册地：广东省深圳市南山区高新技术产业园科技南路中兴通讯大厦，武汉丰迈信息技术有限公司注册地：武汉市东湖新技术开发区光谷大道77号金融后台服务中心基地建 设项目二期2.7期B22栋5层02室02号）（以下简称“我们”）为用户（以下简称“您”） 提供。我们非常重视您的隐私和个人信息保护，为了保护您的个人信息安全，我们制订《中 兴捧月巧匠精英挑战赛隐私政策》（以下简称“本政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息。</p>
      <p>您应当在仔细阅读、充分理解本政策后，选择是否同意本政策的内容以及是否同意使用本产品与/或服务。本政策的所有重点内容，我们采用<b>“加粗”</b>的书写方式进行提示，请您<b>特别关注</b>。</p>
      <p>如果您对本政策或相关事宜有任何问题，您可以通过本政策第<b>【八】</b>条所述的方式联系我们，我们将在核实您的身份后及时响应您的请求，但法律法规另有规定的或本政策另有约定的除外</p>
      <p><b>本政策将帮助您了解以下内容：</b></p>
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>二、我们如何使用Cookie和同类技术</p>
      <p>三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>四、我们如何保护您的个人信息</p>
      <p>五、您的权利</p>
      <p>六、您的个人信息如何在全球范围转移</p>
      <p>七、本政策如何更新</p>
      <p>八、如何联系我们</p>
      <h4>一、我们如何收集和使用您的个人信息</h4>
      <p>我们根据正当、最小必要、透明的原则，基于本政策所述目的，收集和使用您的个人信息。 如果我们将您的个人信息用于本政策未载明的其它用途，或基于其他特定目的而收集、使用您的个人信息，我们将以合理的方式告知您，并在收集、使用前再次征得您的同意。我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
      <p>竞赛用户账号注册您首先需要注册一个竞赛网站账号成为中兴捧月巧匠精英挑战赛的注册 用户。当您注册时，您需要向我们提供您本人的手机号，我们将通过发送短信验证码的方式来验证您的身份是否有效。如果您不提供该信息，不影响您通过zte.fmaster.cn浏览官网信息。注：您的手机号码在您没有主动或同意交换的情况下，不会展示给其他用户。</p>
      <p><b>报名时的必填项有：姓名、邮箱、手机号、身份证号码、性别、学校、学校省份、学院、专业。</b></p>
      <h4>二、我们如何使用Cookie和同类技术</h4>
      <p>（一）Cookie</p>
      <p>为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够存储您的偏好或购物篮内的商品等数据。我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
      </p>
      <h4>三、我们如何委托处理、共享、转让、公开、披露您的个人信息</h4>
      <p>为了保证您的个人信息安全，我们遵照最小化原则，在遵守适用的法律要求下，委托处理、共享、转让或披露您的个人信息。</p>
      <p>对我们与之共享个人信息的公司、组织和个人，我们会按照当地国家的法律要求采取组织和技术措施，要求他们按照我们的安全标准、本政策的要求及相关的保密和安全措施来处理个人信息。
      </p>
      <h4>（一）委托处理</h4>
      <p>为了向您提供更完善、更优质的产品和服务，某些功能可能由我们的技术服务合作方提供，我们可能会委托合作方（包括技术服务提供方）处理您的某些个人信息。对我们委托处理您个人信息的公司、组织和个人，我们会与其签署严格的保密协定，明确数据处理事项、处理期限、处理性质、处理目的以及双方责任等，要求合作方仅按照我们的要求、本政策的规定以及其他任何相关的保密和安全措施来处理您的个人信息。
      </p>
      <h4>（二）共享</h4>
      <p>我们不会与其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
      <p>
        1、基于您的要求或事先获得您的明示授权或同意；
      </p>
      <p>
        2、我们履行法定义务所必需的共享。
      </p>
      <p>我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享为您提供服务所必要的个人信息。我们不会共享可以识别您身份的个人信息，除非法律法规另有规定。
      </p>
      <h4>（三）转让</h4>
      <p>原则上我们不会将您的个人信息控制权向其他公司、组织或个人进行转让。但以下情况除外：</p>
      <p> 1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信 息；</p>
      <p>2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。变更后的运营主体将继续履行个人信息控制者的责任和义务；如公司发生破产或者停运且无承接方的，我们将对您的个人信息做删除或者匿名化处理。
      </p>
      <h4>
        （四）公开披露
      </h4>
      <p>
        我们不会公开您的个人信息，但基于与您的约定或根据法律法规应当披露的除外。
      </p>
      <h4>四、我们如何保存、保护您的个人信息</h4>
      <p>我们十分重视您的个人信息安全，为此我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施，以最大程度防止您的个人信息被泄露、毁损、误用、未授权访问、未授权披露或更改，例如：
      </p>
      <p>1、数据安全技术措施</p>
      <p>
        在数据安全传输方面，采用加密传输技术，保护数据在其传输过程中的安全，对敏感信息采取安全保护措施，并使用妥善的保护机制以防止数据遭到恶意攻击。对数据生命周期的全流程进行监控，防止您的个人信息遭遇未授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失等。
      </p>
      <p>2、数据安全组织和管理措施</p>
      <p>
        建立了相关的内控管理流程，以最小授权为原则，对可能接触到您个人信息的工作人员，严格设定信息访问权限，控制个人信息的知悉范围。组织员工参加安全与隐私保护相关培训并要求其完成规定的考核，加强员工对于保护个人信息重要性的认知。
      </p>
      <p>3、合作协议条款保证</p>
      <p>
        在我们与第三方或业务合作伙伴（简称“合作伙伴”）进行合作之前，我们会明确以书面形式（如合作协议、承诺书）明确数据保护条款，确保他们承担相应的信息保护义务与责任，严格约定第三方或合作伙伴对用户信息的保密义务，包括信息的保管、使用和流转等均应满足我们的管控要求，接受我们的审核、监督和审计，一旦有任何违反，我们会要求对方承担相应的法律责任。
      </p>
      <p>4、安全事件的处理</p>
      <p>
        我们会制定网络安全事件应急预案，如不幸发生了个人信息安全事件，我们将立即启动应急预案，采取补救措施，记录事件内容，按照《国家网络安全事件应急预案》等有关规定及时上报。若可能会给您的合法权益造成严重损害的，如造成敏感个人信息的泄露，我们将向您告知该安全事件的基本情况和其可能的影响、我们已采取或将要采取的处置措施、您可选择的防范和降低风险的建议、我们针对您提供的补救措施以及个人信息保护负责人和个人信息保护工作机构的联系方式等。我们将及时将以上事件相关情况以邮件、信函、电话或推送通知等方式告知您，难以逐一告知时，我们会采取合理、有效的方式发布警示公告。
      </p>
      <p>请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本政策第九条提供的联系方式联系我们，以便我们采取相应措施来保护您的信息安全。</p>
      <h4>
        五、您的权利
      </h4>
      <h4>（一）访问和更正您的个人信息</h4>
      <p>
        您有权随时登录并访问您的账号，查看您的个人信息，如您发现我们处理您的个人信息有错误或不完整的，或者在法律法规规定的其他情况下，您可以进行更正或补充完善。如果您无法通过上述方式访问、更正这些个人信息，通过本政策第【八】条所述的方式联系我们，我们将在15个工作日内响应您的请求。
      </p>
      <h4>（二）删除您的个人信息</h4>
      <p>在以下情形中，您可以向我们提出删除您个人信息的请求，但已做数据匿名化处理或法律法 规另有规定的除外：</p>
      <p>1、处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
      <p>2、我们停止提供产品或者服务，或者保存期限已届满；</p>
      <p>3、您撤回同意的；</p>
      <p>4、我们违反法律、行政法规或者违反约定处理个人信息；</p>
      <p>5、法律法规等规定的其他情形。</p>
      <p><b>当我们决定响应您的删除请求时，我们将尽快从服务器中删除您的信息，但法律法规另有规定的除外。请您知悉，我们可能不会将您需要删除的信息立即从备份系统中删除，但会在备份系统更新时及时删除。</b></p>
      <p>如您希望删除您的其他个人信息，或在行使上述权利过程中遇到困难，您可通过本政策第【八】条所述的方式联系我们，我们将在核实您的身份后及时响应您的请求，但法律法规另有规定的或本政策另有约定的除外。</p>
      <h4>（三）注销您的账号</h4>
      <p>除非法律法规另有规定或本政策另有约定，您可以通过本政策第<b>【八】</b>条所述的方式联系我们注销您的账号。请您知悉，在注销账号后，除非根据法律法规规定需要留存个人信息的，我们将及时删除您的个人信息或对其作匿名化处理。<b>您注销账号的行为是不可逆的，一旦您的账号被注销，我们将不再为您提供本产品与/或服务，也不再收集您的个人信息，因此我们建议您谨慎选择注销账号。</b></p>
      <h4>六、您的个人信息存储及转移</h4>
      <p>我们承诺，您的个人信息将只存储在位于中华人民共和国境内的服务器上，您的信息不会被我们主动传输到境外。如在符合适用法律规定的情形下因业务需要向境外传输个人信息的，我们会事先征得您的同意，向您告知用户信息出境的目的、接收方名称和联系方式、个人信息处理方式、出境的个人信息种类、向境外接收方行使个人信息权利的方式和程序、安全保障措施、安全风险等情况，并依法开展个人信息保护影响评估。
      </p>
      <p>如果您在中华人民共和国境外报名参加中兴捧月竞赛，则意味着您的信息将会被存储在中华人民共和国境内的服务器。</p>
      <p>
        一般而言，我们仅为实现目的所必须的最短时间保留您的个人信息。为满足任何法律、安全、报告或会计方面的要求。如果本隐私政策没有特别说明，我们将会在达成本隐私政策所述目的所需的期限内保留您的个人信息，除非法律要求、许可或允许延长保留期。
      </p>
      <h4>
        七、本政策如何更新
      </h4>
      <p>
        <b>
          我们保留不时更新或修改本政策的权利。我们会在本页面上发布对本政策所做的任何变更。但是未经您明确同意，我们不会削减您按照本政策所应享有的权利。该等变更构成本政策的一部分。若涉及重大、实质性变更，我们会依据具体情况，以邮件的方式通知您。重大、实质性变更的情形包括但不限于以下情形：
        </b>
      </p>
      <p>
        1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p>
        2、您参与个人信息处理方面的权利及其行使方式发生重大变化；
      </p>
      <p>
        3、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p>
        4、个人信息共享、转让或公开披露的主要对象发生变化；
      </p>
      <p>
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </p>
      <p>
        6、个人信息安全影响评估报告表明存在高风险时。
      </p>
      <p>
        <b>
          请您仔细阅读变更后的本政策内容。如果您暂不同意更新，您也可以选择停止使用本平台产品/服务。
        </b>
      </p>
      <h4>八、如何联系我们</h4>
      <p>
        我们设立了专门负责个人信息保护的部门，如您对本政策有任何疑问、意见或建议，您可以通过以下方式与我们取得联系：
      </p>
      <p>
        中兴通讯股份有限公司联系方式：Privacy@zte.com.cn
      </p>
      <p>
        武汉丰迈信息技术有限公司联系方式：cc@fmaster.cn
      </p>
      <p>
        <b>我们会在收到您的意见及建议后的15个工作日内完成身份核验和响应处理。</b>如无法响应您的请求，我们会在法律规定的最大时限要求内，向您发送通知并解释原因。
      </p>
      <p>
        如您对我们的回复不满意，或我们对您个人信息的处理方式侵害了您的合法权益，且在您向我们反馈后我们无法提供让您满意的回复且拒绝改进的，您可以向相关监管机构进行投诉和举报。
      </p>
    </div>
    <div class="bottom" v-if="showBottom">
      <el-button type="primary" @click="handleAgree" :disabled="timeValue>0">同 意<span
        class="time" v-if="timeValue>0">({{timeValue}}秒)</span>
      </el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>

  </el-dialog>

</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: '',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      showBottom: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        timeValue: 10,
        timer: null
      }
    },
    computed: {
      ...mapState(['isMobilePlatform']),
      visible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    },
    components: {},

    destroyed() {
      this.clearReadProtocolTimer()
    },
    methods: {
      handleOpen() {
        this.timeValue = 10;
        this.timer = null;
        if (this.showBottom) {
          this.readProtocolTimer();
        }
      },
      handleClose() {
        this.visible = false;
        this.clearReadProtocolTimer()
      },
      readProtocolTimer(seconds = this.timeValue) {
        this.clearReadProtocolTimer()
        this.timeValue = seconds
        this.timer = setInterval(() => {
          this.timeValue--
          if (this.timeValue === 0) {
            this.clearReadProtocolTimer()
          }
        }, 1000)
      },
      clearReadProtocolTimer() {
        if (this.timer) {
          clearInterval(this.timer)
        }
      },
      handleAgree() {
        let scrollDom = this.$refs.descContent;
        // console.log("scrollDom.scrollTop", scrollDom.scrollTop);
        // console.log("scrollDom.clientHeight", scrollDom.clientHeight);
        // console.log("scrollDom.scrollHeight", scrollDom.scrollHeight)
        let nums = Math.ceil(scrollDom.scrollTop + scrollDom.clientHeight) - Math.ceil(scrollDom.scrollHeight)
        if (Math.abs(nums) < 20) {
          this.$emit('on-agree')
        } else {
          this.$message.error("请滚动阅读完隐私协议");
        }
      }
    }
  }
</script>

<style lang="scss">
  .secret-description-dialog {
    margin-top: 2vh !important;

    h3 {
      text-align: center;
    }
    .desc-content {
      height: 600px;
      overflow-y: auto;

      p {
        color: #222222;
      }
    }

    .mobile-desc-content{
      height:60vh;
    }

    .bottom {
      margin-top: 20px;
      text-align: center;
      .time {
        color: #0084FF;
      }
    }

  }
</style>