import request from "@/plugins/axios"

const basePath = '/tocUserManage'

/**
 * 用户注册
 * @param {Object} obj
 */
export function register(obj) {
  return request({
    url: `${basePath}/register`,
    method: "post",
    data: obj
  })
}

/**
 * 重置密码
 * @param {Object} obj
 */
export function retrievePassword(obj) {
  return request({
    url: `${basePath}/retrievePassword`,
    method: "post",
    data: obj
  })
}
