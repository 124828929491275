<template>
  <div class="login-form">
    <el-form ref="form" :model="model" :rules="rules" size="medium" v-loading="loading">
      <!-- 用户名 -->
      <el-form-item prop="username">
        <el-input v-model="model.username" placeholder="请输入手机号" prefix-icon="el-icon-phone" clearable
                  @keyup.native.enter="handleLogin"/>
      </el-form-item>

      <!-- 密码 -->
      <el-form-item prop="password">
        <el-input v-model="model.password" placeholder="请输入密码" prefix-icon="el-icon-lock" autocomplete="new-password"
                  clearable show-password
                  @keyup.native.enter="handleLogin"/>
      </el-form-item>

      <el-form-item v-if="isMobilePlatform?website.mobileValidateCode:website.validateCode" prop="code">
        <Verify ref="verify" :mode="'pop'" :captcha-type="'blockPuzzle'" :img-size="{ width: '330px', height: '155px' }"
                @success="verifySuccess"/>
      </el-form-item>

      <el-form-item>
        <div class="desc-box">
          <span>忘记密码？去<span class="desc-key" @click="handleResetPassword">重置</span></span>
          <span>没有账号，去<span class="desc-key" @click="handleRegister">注册</span></span>
        </div>
      </el-form-item>

      <!-- 登录按钮 -->
      <el-form-item>
        <el-button class="form-login" type="primary" :loading="loading" @click="handleLogin">登 录</el-button>
      </el-form-item>
    </el-form>

    <!--隐私政策弹窗 -->
    <secret-description-dialog v-model="showSecret" @on-agree="handleAgree"/>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex"
  import website from '@/const/website'
  import Verify from "@/components/verifition/Verify"
  import SecretDescriptionDialog from './secret-description-dialog';

  export default {
    name: "loginForm",
    components: {
      Verify,
      SecretDescriptionDialog
    },
    data() {
      return {
        website,
        loading: false,
        model: {},
        rules: {
          username: [
            {required: true, message: '请输入手机号'}
          ],
          password: [
            {required: true, message: '请输入密码'}
          ]
        },
        showSecret: false
      }
    },
    computed: {
      ...mapState(['isMobilePlatform']),
    },
    created() {
      this.model = {}
    },
    mounted() {
      this.$refs['form']?.clearValidate()
    },
    methods: {
      ...mapActions(['LoginByUsername']),
      /**
       * 点击 登录 按钮
       */
      handleLogin() {
        this.loading = true
        this.$refs['form']?.validate((valid) => {
          if (valid) {
            this.loading = false
            if(this.isMobilePlatform){
              // 直接登录 不需要验证码
              this.loginByUsername()
            }else{
              this.$refs['verify']?.show()
            }
          } else {
            this.loading = false
          }
        })
      },
      /**
       * 登录请求
       */
      loginByUsername() {
        this.loading = true
        let str = this.isMobilePlatform ? website.mobileFormLoginClient : website.formLoginClient;
        // console.log("login form str", str);
        this.LoginByUsername({
          ...this.model,
          clientId: str
        })
          .then(() => {
            this.$message.success('登录成功！')
            this.$emit('submit-success')
          }).finally(() => {
          this.loading = false
        })
      },
      /**
       * 验证通过
       * @param params
       */
      verifySuccess(params) {
        this.model.code = params.captchaVerification
        this.loginByUsername()
      },
      /**
       * 去注册
       */
      handleRegister() {
        this.showSecret = true;
        // this.$emit('change-type', 'register')
      },
      handleAgree() {
        this.showSecret = false;
        this.$emit('change-type', 'register')
      },
      /**
       * 去重置密码
       */
      handleResetPassword() {
        this.$emit('change-type', 'forget')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-form-item:has(.verifybox) {
    margin-bottom: 0;
  }
</style>
