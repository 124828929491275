import request from '@/plugins/axios'

const basePath = '/sms'

/**
 * 发送注册短信验证码
 * @param {String} phoneNumber 手机号
 */
export function sendRegisterSms(phoneNumber) {
  return request({
    url: `${basePath}/sendSms/${phoneNumber}`,
    method: 'get'
  })
}

/**
 * 重置密码短信验证码
 * @param phoneNumber
 */
export function sendResetSms(phoneNumber) {
  return request({
    url: `${basePath}/sendRSms/${phoneNumber}`,
    method: 'get'
  })
}
