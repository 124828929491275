import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"

Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    name: 'home',
    path: '/',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/home'),
  },
  {
    name: 'personal-center',
    path: '/personal-center',
    component: () => import(/* webpackChunkName: 'personal-center' */ '@/views/personal-center'),
    meta: {
      isAuth: true
    }
  },
  {
    name: 'sign-up-management',
    path: '/sign-up-management',
    component: () => import(/* webpackChunkName: 'sign-up-management' */ '@/views/sign-up-management'),
    meta: {
      isAuth: true
    }
  },
  {
    name: 'bridge',
    path: '/bridge',
    component: () => import(/* webpackChunkName: 'bridge' */ '@/views/bridge')
  },
  {
    name: 'mobile-personal-center',
    path: '/mobile-personal-center',
    component: () => import(/* webpackChunkName: 'mobile-personal-center' */ '@/views/mobile-personal-center'),
    meta: {
      isAuth: true,
    }
  },
  {
    name: 'mobile-sign-up-management',
    path: '/mobile-sign-up-management',
    component: () => import(/* webpackChunkName: 'mobile-sign-up-management' */ '@/views/mobile-sign-up-management'),
    meta: {
      isAuth: true,
    }
  },
  {
    name: 'mobile-bridge',
    path: '/mobile-bridge',
    component: () => import(/* webpackChunkName: 'bridge' */ '@/views/mobile-bridge'),
    meta:{
      withoutLayout: true
    }
  },
  {
    name:'pc-description',
    path:'/pc-description',
    component: () => import(/* webpackChunkName: 'bridge' */ '@/views/pc-description'),
    meta:{
      withoutLayout: true
    }
  },
  {
    path: "*",
    redirect: "/"
  }
]

//创建路由
export const createRouter = () => new VueRouter({
  // hash history
  // https://v3.router.vuejs.org/zh/api/#mode
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

const Router = createRouter()

// 在Vue应用实例化之前设置路由守卫
Router.beforeEach((to, from, next) => {
  let { name, fullPath, meta } = to || {}
  let { isAuth = false } = meta || {}
  let { isLogin = false } = store?.getters || {}
  // 判断是否需要登录
  if (isAuth) {
    if (!isLogin) {
      store.commit('SET_LOGIN_REDIRECT_ROUTE', {
        name,
        fullPath
      })
      store.commit('SET_LOGIN_VISIBLE', true)
      return
    }
  }
  next()
})

export default Router
