import request from '@/plugins/axios'
import qs from 'qs'
import store from '@/store'
import {getStore, setStore} from '@/utils/store.js'
import {validatenull} from '@/utils/validate'
import website from '@/const/website.js'
import {checkToken as checkUserToken} from "@/api/admin/token"

const basePath = '/oauth2'

const scope = 'server'

export const loginByUsername = (username, password, code, randomStr) => {
  const grant_type = 'password'
  const dataObj = qs.stringify({username: username, password: password})
  let str = store.state.isMobilePlatform ? website.mobileFormLoginClient : website.formLoginClient;
  console.log("str is", str)
  const basicAuth = 'Basic ' + window.btoa(str)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: `${basePath}/token`,
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: basicAuth
    },
    method: 'post',
    params: {randomStr, code, grant_type, scope},
    data: dataObj
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  // 获取当前选中的 basic 认证信息
  const basicAuth = getStore({name: 'basicAuth'})

  return request({
    url: `${basePath}/token`,
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: basicAuth
    },
    method: 'post',
    params: {refresh_token, grant_type, scope}
  })
}

export const loginByMobile = (mobile, code) => {
  const grant_type = 'mobile'
  const basicAuth = 'Basic ' + window.btoa(website.smsLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: `${basePath}/token`,
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: basicAuth
    },
    method: 'post',
    params: {mobile: 'SMS@' + mobile, code: code, grant_type, scope}
  })
}

export const loginBySocial = (state, code) => {
  const grant_type = 'mobile'
  const basicAuth = 'Basic ' + window.btoa(website.socialLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: `${basePath}/token`,
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: basicAuth
    },
    method: 'post',
    params: {mobile: state + '@' + code, code: code, grant_type, scope}
  })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshTime
 * @param refreshLock
 * @param $store
 */
export const checkToken = (refreshTime, refreshLock, $store) => {
  const token = store.getters.access_token
  // 获取当前选中的 basic 认证信息
  const basicAuth = getStore({name: 'basicAuth'})

  checkUserToken(basicAuth, token)
    .then((response) => {
      if (validatenull(response)) {
        clearInterval(refreshTime)
        return
      }

      const expire = response && response.data && response.data.exp
      if (expire) {
        const expiredPeriod = expire * 1000 - new Date().getTime()
        //小于半小时自动续约
        if (expiredPeriod <= 10 * 60 * 1000) {
          if (!refreshLock) {
            refreshLock = true
            $store.dispatch('RefreshToken').catch(() => {
              clearInterval(refreshTime)
            })
            refreshLock = false
          }
        }
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

/**
 * 注册用户
 */
export const registerUser = (userInfo) => {
  return request({
    url: '/register/user',
    method: 'post',
    data: userInfo
  })
}

/**
 * 获取网站设置
 */
export function fetchSetUpList() {
  return request({
    url: `/org/webSetup/page/web_setup`,
    method: 'get'
  })
}
