const state = {
  flag: false
}

const mutations = {
  mtt_flag(state, data) {
    state.flag = data;
  }
}

export default {
  namespaced: true,
  state,
  mutations
};
