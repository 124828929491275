import Vue from 'vue'
import Vuex from 'vuex'
import website from '@/const/website'
import {encryption} from '@/utils'
import {getStore, setStore} from '@/utils/store'
import {loginByUsername, refreshToken} from '@/api/login'
import {logout} from "@/api/admin/token"
import {getTocUserInfo} from "@/api/admin/toc-user"
import personalCenter from './modules/personal-center'
import pcDescription from './modules/pc-description'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reLogin: false,
    logOnRunRoute: null,
    loginVisible: false,
    loginRedirectRoute: null,
    access_token: getStore({
      name: 'access_token'
    }) || '',
    access_token_exp_time: getStore({
      name: 'access_token_exp_time'
    }) || '',
    refresh_token: getStore({
      name: 'refresh_token'
    }) || '',
    tocUserInfo: getStore({
      name: 'tocUserInfo'
    }) || {},
    registerVerifyCodeTimeMap: getStore({
      name: 'registerVerifyCodeTimeMap'
    }) || {},
    resetVerifyCodeTimeMap: getStore({
      name: 'resetVerifyCodeTimeMap'
    }) || {},
    isMobilePlatform: getStore({
      name: 'is_mobile_platform'
    }) || {},
    openRegistration: false
  },
  getters: {
    access_token: state => {
      const nowTime = new Date().getTime()
      // token有效期检查
      if (state.access_token_exp_time > nowTime) {
        return state.access_token
      }
      return ""
    },
    isLogin: (state, getters) => !!getters.access_token,
    authorization: (state, getters) => `Bearer ${getters.access_token}`,
    headers: (state, getters) => {
      return {
        Authorization: getters.authorization,
        'TENANT-ID': getters.tenantId
      }
    },
    userInfo: state => state.tocUserInfo,
    tenantId: (state, getters) => getters.userInfo.tenantId ? getters.userInfo.tenantId : website.defTenantId,
  },
  mutations: {
    SET_RE_LOGIN_STATUS: (state, bool = false) => {
      state.reLogin = bool
    },
    SET_LOGON_RUN_ROUTE: (state, route = null) => {
      state.logOnRunRoute = route
    },
    SET_LOGIN_VISIBLE: (state, visible) => {
      state.loginVisible = visible
    },
    SET_LOGIN_REDIRECT_ROUTE: (state, route) => {
      state.loginRedirectRoute = route
    },
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: state.access_token
      })
    },
    SET_ACCESS_TOKEN_EXP_TIME: (state, access_token_exp_time) => {
      state.access_token_exp_time = access_token_exp_time
      setStore({
        name: 'access_token_exp_time',
        content: state.access_token_exp_time
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token
      })
    },
    SET_TOC_USER_INFO: (state, tocUserInfo) => {
      state.tocUserInfo = tocUserInfo
      setStore({
        name: 'tocUserInfo',
        content: tocUserInfo,
        type: 'session'
      })
    },
    /**
     * 更新注册手机号验证码发送时间
     * @param {Object} state
     * @param {String} mobile 手机号
     */
    REGISTER_VERIFY_CODE_SEND_TIME: (state, mobile) => {
      state.registerVerifyCodeTimeMap[mobile] = new Date().getTime()
      setStore({
        name: 'registerVerifyCodeTimeMap',
        content: state.registerVerifyCodeTimeMap,
        type: 'session'
      })
    },
    /**
     * 更新重置密码手机号验证码发送时间
     * @param {Object} state
     * @param {String} mobile 手机号
     */
    RESET_VERIFY_CODE_SEND_TIME: (state, mobile) => {
      state.resetVerifyCodeTimeMap[mobile] = new Date().getTime()
      setStore({
        name: 'resetVerifyCodeTimeMap',
        content: state.resetVerifyCodeTimeMap,
        type: 'session'
      })
    },
    /**
     * 判断是pc还是手机端
     */
    SET_IS_MOBILE_PLATFORM: (state, payload) => {
      state.isMobilePlatform = payload;
      setStore({
        name: 'is_mobile_platform',
        content: state.isMobilePlatform
      })
    },
    SET_OPENREGISTRATION: (state, payload) => {
      state.openRegistration = payload
    }
  },
  actions: {
    /**
     * 查询toc用户信息
     */
    GetTocUserInfo({commit}) {
      return new Promise((resolve, reject) => {
        getTocUserInfo().then((res) => {
          const data = res.data.data || {}
          commit('SET_TOC_USER_INFO', data)
          resolve(data)
        }).catch(() => {
          reject()
        })
      })
    },
    // 根据用户名登录
    LoginByUsername({commit, dispatch}, userInfo) {
      let user = {}
      // 编码处理
      if (website.passwordEnc) {
        user = encryption({
          data: userInfo,
          key: website.encPassword,
          param: ['password']
        })
      } else {
        user = userInfo
      }
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, userInfo.clientId, user.code, user.randomStr).then(response => {
          const data = response.data
          commit('SET_ACCESS_TOKEN', data.access_token)
          commit('SET_ACCESS_TOKEN_EXP_TIME', data.exp * 1000)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          // 获取登录用户信息、角色信息、权限信息
          dispatch('GetTocUserInfo').finally(() => {
            resolve()
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 刷新token
    RefreshToken({commit, state}) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token).then(response => {
          const data = response.data
          commit('SET_ACCESS_TOKEN', data.access_token)
          commit('SET_ACCESS_TOKEN_EXP_TIME', data.exp * 1000)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 注销session
    FedLogOut({commit}) {
      return new Promise(resolve => {
        let fistPage = website.fistPage || {}
        let currentRoute = router.currentRoute
        let aRoute = {
          path: fistPage.value,
          params: fistPage.params,
          query: fistPage.query
        }
        let {meta, matched} = currentRoute
        if (!(meta.isAuth || matched.some(m => m.meta.isAuth))) {
          // 不需要登录授权
          aRoute = {
            path: currentRoute.path,
            params: currentRoute.params,
            query: currentRoute.query
          }
        }
        commit('SET_TOC_USER_INFO', {})
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_ACCESS_TOKEN_EXP_TIME', null)
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_LOGON_RUN_ROUTE', aRoute)
        resolve()
      })
    },
    // 退出登录
    LogOut({dispatch}) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          dispatch('FedLogOut').then(() => {
            resolve()
          }).catch(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  modules: {
    personalCenter,
    pcDescription
  }
})
