import axios from 'axios'
import qs from 'qs'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Message, MessageBox } from 'element-ui'
import website from '@/const/website'
import errorCode from '@/const/errorCode'
import router from '@/router'
import store from '@/store'
import { serialize } from '@/utils'
import { aesDecrypt, aesEncrypt } from '@/utils/encryption'
import { getStore } from '@/utils/store'
import { logoutUrl } from "@/api/admin/token"

const CONTEXT_PATH = website.contextPath

axios.defaults.timeout = 30000
// 返回其他状态码
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = window._CONFIG['domainURL'] + CONTEXT_PATH
} else {
  axios.defaults.baseURL = CONTEXT_PATH
}
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTP request拦截
axios.interceptors.request.use(config => {
  NProgress.start() // start progress bar
  const TENANT_ID = getStore({ name: 'tenantId' })
  const isToken = (config.headers || {}).isToken === false
  const isLogin = store.getters.isLogin
  if (isLogin && !isToken) {
    config.headers['Authorization'] = store.getters.authorization
  }
  if (TENANT_ID) {
    config.headers['TENANT-ID'] = TENANT_ID // 租户ID
  }

  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  // 请求报文加密
  if (config.headers['Enc-Flag']) {
    config.data = {
      encryption: aesEncrypt(JSON.stringify(config.data), website.encPassword)
    }
  }

  if (config.method === 'get') {
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

// HTTP response拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  const message = res.data.msg || errorCode[status] || errorCode['default']

  // 后台定义 424 针对令牌过期的特殊响应码
  if (status === 424) {
    if (!store.state.reLogin) {
      if (res.config.url.includes(logoutUrl)) {
        return
      }
      // 记录当前页面，登录后重新跳转
      store.commit("SET_LOGON_RUN_ROUTE", router.currentRoute.fullPath)
      store.commit("SET_RE_LOGIN_STATUS", true)
      // 关闭messageBox弹窗
      // this.$msgbox.close()
      MessageBox.confirm('登录已过期，请点击重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          store.dispatch('FedLogOut').then(() => {
            // 跳转到首页
            router.push("/")
              .then(() => {
                store.commit("SET_LOGIN_VISIBLE", true)
              })
            // 刷新登录页面，避免多次弹框
            // window.location.reload()
          })
          store.commit("SET_RE_LOGIN_STATUS")
        })
        .catch(() => {
          store.dispatch("FedLogOut").then(() => {
            // 跳转到首页
            router.push("/")
              .then(() => { })
          })
          store.commit("SET_RE_LOGIN_STATUS")
        })
    }
    return
  }

  if (status !== 200 || res.data.code === 1) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }

  // 针对密文返回解密
  if (res.data.encryption) {
    const originData = JSON.parse(aesDecrypt(res.data.encryption, website.encPassword))
    res.data = originData
    return res
  }

  return res
}, error => {
  // 处理 503 网络异常
  if (error.response.status === 503) {
    Message({
      message: error.response.data.msg,
      type: 'error'
    })
  }
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
