<template>
  <div class="app">
    <transition v-if="isMobilePlatform && !withoutLayout">
      <layout/>
    </transition>
    <transition v-else>
      <router-view/>
    </transition>
    <!--登录注册弹窗-->
    <login-register-dialog @submit-success="handleSubmitSuccess"/>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
  import {validateMobilePlatform} from '@/utils/validate'
  import Layout from './components/layout'
  import LoginRegisterDialog from '@/views/home/components/login-register-dialog'
  import {getSignUpData} from '@/api/personal-center'

  export default {
    name: 'App',
    data() {
      return {
        withoutLayout: true,
        isMobilePlatform: false
      }
    },
    computed: {
      ...mapState(['loginRedirectRoute']),
      ...mapState('pcDescription', ['flag']),
      ...mapGetters([
        'isLogin', // 是否已经登录账号
        'userInfo' // 登录用户信息
      ])
    },
    watch: {
      $route(n) {
        const {
          meta: {withoutLayout}
        } = n;

        this.withoutLayout = !!withoutLayout;
      }
    },
    components: {
      Layout,
      LoginRegisterDialog
    },
    methods: {
      ...mapMutations(['SET_IS_MOBILE_PLATFORM', 'SET_LOGIN_REDIRECT_ROUTE']),
      ...mapActions(['GetTocUserInfo']),
      async loginRunSignUpManagement(routeName) {
        let {id} = this.userInfo || {};
        // console.log("hyl id is", id)
        if (!id) return;
        let res = await getSignUpData(id);
        if (res && res.data && res.data.code == 0) {
          let {signUpList = []} = res.data.data;
          if (signUpList && signUpList.length > 0) {
            let name = this.isMobilePlatform ? 'mobile-personal-center' : 'personal-center'
            this.$router.push({name})
          } else {
            this.$router.push({name: routeName})
          }
        } else {
          this.$router.push({name: routeName})
        }
      },
      async handleSubmitSuccess() {
        if (this.loginRedirectRoute) {
          let {name} = this.loginRedirectRoute || {}
          switch (name) {
            case 'mobile-sign-up-management':
            case 'sign-up-management':
              await this.loginRunSignUpManagement(name)
              break;

            default:
              this.$router.push({
                name
              })
              break;
          }
        }
        this.SET_LOGIN_REDIRECT_ROUTE(null)
      }
    },
    created() {
      if (this.isLogin) {
        this.GetTocUserInfo();
      }
      const isMobilePlatform = validateMobilePlatform();
      this.isMobilePlatform = isMobilePlatform;
      this.SET_IS_MOBILE_PLATFORM(isMobilePlatform)
      // if(isMobilePlatform && !this.flag){
      //   this.$router.push({name:'pc-description'})
      // }
    }
  };
</script>

<style scoped lang="scss">
  .app {

  }
</style>