/**
 * 密码格式校验
 * @param s
 */
export function isValidPassword(s) {
  let valid = true
  let message = ''
  s = s.trim()
  if (s.length < 6) {
    valid = false
    message = '长度不能小于6位'
  }
  if (s.length > 16) {
    valid = false
    message = '长度不能超过16位'
  }
  if (!/[\dA-Za-z]/.test(s)) {
    valid = false
    message = '密码由数字或字母组成'
  }
  return {
    valid,
    message
  }
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  s = s.trim()
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  s = s.trim()
  return /^1[3-9]\d{9}$/.test(s)
}

/**
 * 手机号脱敏
 * @param phoneNumber
 */
export function encryptPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}

/*身份证校验*/
export function isIdCard(card) {
  let re = /^[1-9]\d{5}[1-2]\d{3}((0\d)|(1[0-2]))(([012]\d)|3[0-1])\d{3}(\d|X|x)$/
  return re.test(card)
}

/* 姓名校验 */
export function isName(name) {
  let re = /^[\u2E80-\u9FFF·]{2,60}$/
  return re.test(name)
}

/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    return val === 'null' || val == null || val === 'undefined' || val === undefined || val === ''
  }
  return false
}

/**
 * 判断是否是移动端
 */
export function validateMobilePlatform() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}
