<template>
  <el-dialog custom-class="login-register-dialog"
             :visible="loginVisible" :close-on-click-modal="false"
             :width="isMobilePlatform?'88%':'400px'"
             @open="handleOpen" @close="handleClose">
    <div class="title">{{ title }}</div>

    <template v-if="loginVisible">
      <!-- 账号登录 -->
      <template v-if="type === 'login'">
        <login-form @change-type="type = $event" @submit-success="handleSubmitSuccess"></login-form>
      </template>
      <!-- 新用户注册 -->
      <template v-else-if="type === 'register'">
        <register-form @change-type="type = $event"></register-form>
      </template>
      <!-- 找回密码 -->
      <template v-else-if="type === 'forget'">
        <reset-password-form @change-type="type = $event"></reset-password-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
  import { mapMutations } from "vuex"
  import loginForm from "./login-form"
  import registerForm from "./register-form"
  import resetPasswordForm from "./reset-password-form"
  import {mapState} from 'vuex'

  export default {
    name: 'LoginRegisterDialog',
    components: {
      loginForm,
      registerForm,
      resetPasswordForm
    },
    data() {
      return {
        type: "login", // login 登录 | register 注册 | forget 找回密码
      }
    },
    computed: {
      ...mapState(['isMobilePlatform', 'loginVisible']),
      title() {
        switch (this.type) {
          case "login":
            return '账号登录'

          case "register":
            return '新用户注册'

          case "forget":
            return '找回密码'

          default:
            break
        }
      }
    },
    methods: {
      ...mapMutations(['SET_LOGIN_VISIBLE']),
      handleOpen() {
        this.type = 'login'
      },
      handleClose() {
        this.SET_LOGIN_VISIBLE(false)
      },
      handleSubmitSuccess() {
        this.handleClose()
        this.$emit('submit-success')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-dialog__wrapper {
    ::v-deep {
      .login-register-dialog {
        margin-top: 15vh !important;

        .el-dialog__body {
          padding: 20px 40px;
        }

        .title {
          text-align: center;
          font-size: 24px;
          font-weight: 500;
          color: #01327B;
          margin-top: -10px;
          margin-bottom: 37px;
        }

        .msg-verify-code {
          .el-form-item__content{
            display: flex;
            justify-content: space-between;
          }
          .el-input {
            width: 60%;
            margin-right: 6px;
          }

          .el-button {
            /*min-width: 80px;*/
            flex: 1;
          }
        }

        .desc-box {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;

          > span {
            font-size: 14px;
            color: #3D3D3D;
          }
        }

        .desc-key {
          color: #0084FF;
          font-weight: 500;
          cursor: pointer;
        }

        .form-login {
          width: 100%;
        }
      }
    }
  }
</style>
