const state = {
  personalObj: {},
  signUpList: []
}

const mutations = {
  mtt_personalObj(state, data) {
    state.personalObj = data;
  },
  mtt_signUpList(state, data) {
    state.signUpList = data;
  }
}

export default {
  namespaced: true,
  state,
  mutations
};
