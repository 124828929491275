/** init domain config */
const env = process.env
const _CONFIG = window._CONFIG
const _CONFIG_VUE_APP_API_BASE_URL = _CONFIG.VUE_APP_API_BASE_URL

//设置全局API_BASE_URL
const API_BASE_URL = _CONFIG_VUE_APP_API_BASE_URL
  ? _CONFIG_VUE_APP_API_BASE_URL
  : env.NODE_ENV === 'production'
    ? window.location.origin
    : env.VUE_APP_API_BASE_URL
window._CONFIG['domainURL'] = API_BASE_URL
