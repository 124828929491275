<template>
  <div class="layout">
    <router-view/>

    <mobile-bottom-tab/>
  </div>
</template>

<script>
  import MobileBottomTab from './components/mobile-bottom-tab';

  export default {
    name: '',
    data() {
      return {}
    },
    components: {
      MobileBottomTab
    },
    methods: {}
  }
</script>

<style scoped lang="scss">

</style>