const baseUrl = process.env.BASE_URL
export default {
  title: 'ultralabx 校园版',
  subtitle: 'ultralabx-s',
  copyright: 'Copyright © 2022 www.fmaster.cn. All rights reserved.',
  isFirstPage: true, // 配置首页不可关闭
  key: 'ultralabxs', // 配置主键,目前用于存储
  encPassword: 'fmasterultralabx', // 前后端数据传输的密钥
  validateCode: true, //是否开启验证码校验
  mobileValidateCode: false,
  passwordEnc: true, //是否开启密码加密传输
  codeLogin: false, // 是否开启手机号登录
  thirdLogin: false, // 是否开启第三方登录
  register: false, // 是否开启注册
  connectSync: true, // 是否开启互联同步
  domainAutoTenant: false, // 是否根据 domain 自动匹配租户
  formLoginClient: 'ultralabx:ultralabx', // 管理平台登录的 client 信息
  mobileFormLoginClient: 'ultralabxMobile:ultralabxMobile',
  smsLoginClient: 'app:app', // 验证码登录的 client 信息
  socialLoginClient: 'social:social', // 社交登录的 client 信息
  websocket: false, // 是否开启websocket
  learnWebsocket: true, // 是否开启websocket
  dynamicCodeCache: false, //  是否开启动态代码缓存
  whiteList: ['/login', '/404', '/401', '/lock', '/learningForElabSim'], // 配置无权限可以访问的页面
  whiteTagList: ['/login', '/404', '/401', '/lock', '/learningForElabSim'], // 配置不添加tags页面 （'/advanced-router/mutative-detail/*'——*为通配符）
  // 是否固定页面标题
  staticPageTitle: true,
  pageTitle: "云课时间校园版",
  webForShort: '云课时间',
  address: '武汉市东湖新技术开发区光谷大道77号金融港B22栋5楼',
  phone: '027-87800788',
  technicalSupport: '武汉凌特',
  fistPage: {
    label: '首页',
    value: window._CONFIG.HOME_ROUTE_PATH || '/',
    params: {},
    query: {},
    group: [],
    close: false
  },
  // 配置菜单的属性
  menu: {
    props: {
      label: 'label',
      path: 'path',
      icon: 'icon',
      children: 'children'
    }
  },
  // 默认租户id
  defTenantId: 1,
  // 默认用户头像
  defUserAvatar: `${baseUrl}images/user-avatar.png`,
  contextPath: "/api",
  a4TopBottomPadding: 20,
  a4LeftRightPadding: 83,
  a4MarginTop: 10,
  maxImageWidth: 120
}
