import request from '@/plugins/axios'

const basePath = '/token'

export function fetchList(query) {
  return request({
    url: `${basePath}/page`,
    method: 'post',
    data: query
  })
}

export function delObj(token) {
  return request({
    url: `${basePath}/${token}`,
    method: 'delete'
  })
}

export const logoutUrl = `${basePath}/logout`

export const logout = () => {
  return request({
    url: logoutUrl,
    method: 'delete'
  })
}

export function checkToken(basicAuth, token) {
  return request({
    url: `${basePath}/check_token`,
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'get',
    params: { token }
  })
}
